<template>
  <section
    ref="section"
  >
    <div class="photo"
      :style="{
        backgroundImage: 'url(/img/albums/' + photo + ')'
      }"
    ></div>

    <div class="frame">
      <h1>{{ header }}</h1>
      <p v-html="formattedText"></p>
    </div>

  </section>
</template>

<script>

export default {
  name: 'FrameAlbumLG',
  props: ['posY', 'transitionPhoto', 'photo', 'header', 'text'],
  data: () => ({
    
  }),
  computed: {
    formattedText () {
      return this.text.replace(/\n/g, '<br>')
    },
    settedPhotoHeight () {
      return window.innerWidth > 1300 ? this.photoHeight : 'auto'
    }
  },
  methods: {
    checkFrame () {
      if(this.$refs.section) {
        var y = this.$refs.section.offsetTop
        if (this.posY >= y - window.innerHeight / 2) {
          this.showFrame()
        }
      }
    },
    showFrame () {
      this.$refs.section.style.opacity = 1
      this.$refs.section.style.transform = 'translateX(0)'
    }
  },
  mounted () {
    if (!this.transitionPhoto) {
      this.showFrame()
    }
  },
  created () {
    window.addEventListener('scroll', this.checkFrame)
  }
}
</script>

<style scoped lang="scss">

section {
  display: flex;
  flex-direction: column;
  opacity: 0;
  transform: translateX(-200px);
  transition: 2s all ease-in-out;
  margin: 100px 0;
  @media screen and (min-width: 960px) {
    flex-direction: row;
    justify-content: center;
  }
}
.photo {
  flex-basis: 300px;
  width: 300px;
  z-index: 1;
  background-size: cover;
  background-repeat: no-repeat;
  margin: 0 auto;
  @media screen and (min-width: 450px) {
      flex-basis: 300px;
  }
  @media screen and (min-width: 500px) {
      flex-basis: 300px;
  }
  @media screen and (min-width: 600px) {
      flex-basis: 350px;
      width: 350px;
  }
  @media screen and (min-width: 960px) {
      flex-basis: 380px;
      height: 380px;
      margin: 0 0;
  }
}
.frame {
  margin: -15px 5px 0 5px;
  padding: 25px;
  border: 1px solid #444;
  z-index: 0;
  text-align: center;
  h1 {
      font-family: 'Cormorant Garamond', serif;
      font-size: 3rem;
      letter-spacing: 1px;
      color: #333;
  }
  P {
      margin: 15px 0 20px 0;
      font-size: 1.3rem;
      line-height: 2.8rem;
      color: #666;
  }
  span {
      display: inline-block;
      padding: 10px 25px;
      font-size: 1.2rem;
      color: #333333;
      transition: 1s;
      text-transform: uppercase;
      &.active, &:hover {
          background-color: #EB7054;
          color: #FFFFFF;
      }
  }
  @media screen and (min-width: 960px) {
      flex-basis: 627px;
      margin: 75px 0 75px -50px;
      padding-left: 100px;
  }
  @media screen and (min-width: 1400px) {
      h1 {
          font-size: 4rem;
          letter-spacing: 2px;
      }
      p {
          font-size: 1.4rem;
      }
  }
}

h1 {
    font-family: 'Cormorant Garamond', serif;
    font-size: 3rem;
    letter-spacing: 1px;
    color: #333;
    font-weight: 300;
    @media screen and (min-width: 1400px) {
        font-size: 4rem;
        letter-spacing: 2px;
    }
}

</style>
