<template>
  <div class="subpage">

    <FrameAlbumLG
        :posY="posY"
        :transitionPhoto="false"
        :photo="album.imgName"
        :header="album.title"
        :text="album.description"
    ></FrameAlbumLG>

    <div class="songs">
      <div
        class="song"
        v-for="song in album.songs" :key="song.title"
      >
        <h1>{{ song.title }}</h1>
        <div
          v-if="song.video != ''"
        >
          <iframe width="560" height="415" :src="song.video" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </div>
        <div class="lyrics">
          <p
            v-for="(par, index) in song.lyrics" :key="index"
            :style="[par.params.bold ? {fontWeight: 'bold'} : {}]"
            v-html="breakLine(par.text)"
          ></p>
        </div>
      </div>
    </div>

    <div class="return">
      <button
        type="button"
        class="btn-primary"
        @click="$router.push({ path: '/dyskografia'})"
      >Wróć do dyskografii</button>
    </div>

  </div>
</template>

<script>
import FrameAlbumLG from '@/components/FrameAlbumLG'

export default {
  name: 'AlbumPage',
  props: ['posY'],
  components: {FrameAlbumLG},
  data: () => ({
    
  }),
  computed: {
    albumLink () {
      return this.$route.params.albumLink
    },
    album () {
      return this.$store.state.discography.filter(el => {
        return el.link == this.albumLink
      })[0]
    },
  },
  methods: {
    breakLine (par) {
      return par.replaceAll('\n', '<br>')
    }
  },
  created () {
    
  },
  mounted () {
   
  },
  // beforeRouteUpdate (to, from, next) {
    
  // }
}
</script>

<style scoped lang="scss">

.frame-container {
  display: flex;
  justify-content: center;
  width: 100%;
}
.songs {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  .song {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
}
.lyrics {
  margin: 50px 0;
  p {
    margin-bottom: 25px;
  }
}
.return {
  display: flex;
  justify-content: center;
  margin-top: 100px;
}
h1 {
    font-family: 'Cormorant Garamond', serif;
    font-size: 3rem;
    letter-spacing: 1px;
    color: #333;
    font-weight: 300;
    text-align: center;
    margin-bottom: 50px;
    @media screen and (min-width: 1400px) {
        font-size: 4rem;
        letter-spacing: 2px;
    }
}
iframe {
  width: 280px;
  height: 250px;
  @media screen and (min-width: 600px) {
    width: 560px;
    height: 415px;
  }
}

</style>
